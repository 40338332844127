var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        !_vm.serverSideEncryptionEnabled
          ? _c("div", [
              _vm.canEnableEncryption
                ? _c("div", { staticClass: "row mr-0 ml-0 w-100" }, [_vm._m(0)])
                : _vm._e(),
              _vm.canEnableEncryption
                ? _c("div", { staticClass: "row mr-0 ml-0 w-100" }, [
                    _c(
                      "div",
                      { staticClass: "kt-portlet" },
                      [
                        _c("div", { staticClass: "kt-portlet__head" }, [
                          _c("div", { staticClass: "kt-portlet__head-label" }, [
                            !_vm.isAdminLocked && !_vm.finalized
                              ? _c("div", { staticStyle: { width: "72px" } }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mt-3 kt-switch kt-switch--outline kt-switch--icon",
                                      class: _vm.enableEncryption
                                        ? "kt-switch--success"
                                        : "kt-switch--danger",
                                    },
                                    [
                                      _c("label", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.enableEncryption,
                                              expression: "enableEncryption",
                                            },
                                          ],
                                          attrs: {
                                            disabled:
                                              _vm.serverSideEncryptionEnabled,
                                            type: "checkbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.enableEncryption
                                            )
                                              ? _vm._i(
                                                  _vm.enableEncryption,
                                                  null
                                                ) > -1
                                              : _vm.enableEncryption,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.enableEncryption,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.enableEncryption =
                                                      $$a.concat([$$v]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.enableEncryption = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.enableEncryption = $$c
                                              }
                                            },
                                          },
                                        }),
                                        _c("span"),
                                      ]),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _c(
                              "h3",
                              { staticClass: "kt-portlet__head-title" },
                              [
                                _vm._v(" Setup Encryption "),
                                _c("small", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.enableEncryption
                                          ? "Enabled"
                                          : "Disabled"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c("div", {
                            staticClass: "kt-portlet__head-toolbar",
                          }),
                        ]),
                        _c(
                          "b-overlay",
                          {
                            attrs: {
                              variant: "transparent",
                              center: "",
                              opacity: "1",
                              blur: "10px",
                              show: !_vm.enableEncryption,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "overlay",
                                  fn: function () {
                                    return [_c("p")]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2719199842
                            ),
                          },
                          [
                            _c("div", { staticClass: "kt-portlet__body" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", [
                                  _vm._v("School Term Encryption Password"),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.password,
                                      expression: "password",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid": !_vm.isValidSchoolPassword,
                                  },
                                  attrs: {
                                    type: "password",
                                    name: _vm.randomizeFormName,
                                    autocomplete: "password",
                                  },
                                  domProps: { value: _vm.password },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.password = $event.target.value
                                    },
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "form-text text-muted" },
                                  [
                                    _vm._v(
                                      " Please choose a password to encrypt and decrypt the data for this school term. "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", [
                                  _vm._v(
                                    "Confirm School Term Encryption Password"
                                  ),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.confirmPassword,
                                      expression: "confirmPassword",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: { "is-invalid": !_vm.passwordMatch },
                                  attrs: {
                                    type: "password",
                                    name: _vm.randomizeFormName,
                                    autocomplete: "password",
                                  },
                                  domProps: { value: _vm.confirmPassword },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.confirmPassword = $event.target.value
                                    },
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "form-text text-muted" },
                                  [_vm._v(" Please confirm the password. ")]
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "kt-portlet__foot mb-5" },
                              [
                                _c("div", { staticClass: "kt-form__actions" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-info pull-right",
                                      class: {
                                        "kt-spinner kt-spinner--sm kt-spinner--light":
                                          _vm.saving,
                                      },
                                      attrs: { type: "button" },
                                      on: {
                                        click: _vm.setSchoolTermEncryptionKey,
                                      },
                                    },
                                    [_vm._v(" Set Encryption Password ")]
                                  ),
                                  _vm.isSetupWizard &&
                                  _vm.step.status == "danger"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-secondary pull-right mr-2",
                                          attrs: { type: "button" },
                                          on: { click: _vm.skipSetupWizard },
                                        },
                                        [_vm._v(" Skip this Step ")]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ])
          : [
              _c("div", { staticClass: "col col-lg-6 offset-lg-3 pt-5" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "kt-portlet kt-bg-warning kt-portlet--skin-solid kt-portlet--height-fluid",
                  },
                  [
                    _c("div", { staticClass: "kt-portlet__body" }, [
                      _c(
                        "div",
                        { staticClass: "kt-widget7 kt-widget7--skin-light" },
                        [
                          _c("div", { staticClass: "kt-widget7__desc my-3" }, [
                            _vm._v(
                              " Remove encryption for this school term by clicking the button below. This will remove the encryption key and all data will be decrypted for this term for all staff. "
                            ),
                          ]),
                          _vm._m(1),
                          _c("div", { staticClass: "kt-widget7__button" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-danger btn-lg kt-font-lg btn-upper mt-3",
                                staticStyle: {
                                  "min-width": "260px",
                                  margin: "auto",
                                },
                                attrs: { type: "button" },
                                on: { click: _vm.removeEncryption },
                              },
                              [_vm._v(" Remove School Term Encryption ")]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "alert alert-light alert-elevate fade show w-100",
        attrs: { role: "alert" },
      },
      [
        _c("div", { staticClass: "alert-icon" }, [
          _c("i", { staticClass: "la la-info-circle kt-font-danger" }),
        ]),
        _c("div", { staticClass: "alert-text" }, [
          _vm._v(
            " By choosing a School Term Encryption Password, you are enabling an additional layer of encryption to protect student personal identifiable information (PII) in our data systems at rest. "
          ),
          _c("p", { staticClass: "kt-font-bolder pt-3" }, [
            _vm._v(
              " All Staff who need to access student PII, will need to know this password. "
            ),
          ]),
          _vm._v(
            " This third factor of authentication and resulting encryption keys are intentionally not stored by our systems. Please choose a memorable high entrophy password. "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget7__content" }, [
      _c("div", { staticClass: "kt-widget7__userpic" }, [
        _c("img", {
          staticStyle: { width: "260px", "min-width": "200px" },
          attrs: { src: "/images/lock.svg" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }