<template>
<div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <!-- first time setup -->
        <div v-if="!serverSideEncryptionEnabled">
            <div v-if="canEnableEncryption" class="row mr-0 ml-0 w-100">
                <div
                    class="alert alert-light alert-elevate fade show w-100"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="la la-info-circle kt-font-danger" />
                    </div>
                    <div class="alert-text">
                        By choosing a School Term Encryption Password, you are enabling an additional layer of encryption to protect
                        student personal identifiable information (PII) in our data systems at rest.
                        <p class="kt-font-bolder pt-3">
                            All Staff who need to access student PII, will need to know this password.
                        </p>
                        This third factor of authentication and resulting encryption keys are intentionally not stored by our systems.
                        Please choose a memorable high entrophy password.
                    </div>
                </div>
            </div>
            <div v-if="canEnableEncryption" class="row mr-0 ml-0 w-100">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <div v-if="!isAdminLocked && !finalized" style="width: 72px">
                                <span class="mt-3 kt-switch kt-switch--outline kt-switch--icon" :class="enableEncryption ? 'kt-switch--success' : 'kt-switch--danger'">
                                    <label>
                                        <input
                                            v-model="enableEncryption"
                                            :disabled="serverSideEncryptionEnabled"
                                            type="checkbox"
                                        >
                                        <span />
                                    </label>
                                </span>
                            </div>
                            <h3 class="kt-portlet__head-title">
                                Setup Encryption
                                <small>
                                    {{ enableEncryption ? 'Enabled' : 'Disabled' }}
                                </small>
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar" />
                    </div>
                    <b-overlay
                        :variant="'transparent'"
                        center
                        opacity="1"
                        blur="10px"
                        :show="!enableEncryption"
                    >
                        <template #overlay>
                            <p />
                        </template>
                        <div class="kt-portlet__body">
                            <div class="form-group">
                                <label>School Term Encryption Password</label>
                                <input
                                    v-model="password"
                                    type="password"
                                    class="form-control"
                                    :name="randomizeFormName"
                                    :class="{'is-invalid': !isValidSchoolPassword}"
                                    autocomplete="password"
                                >
                                <span class="form-text text-muted">
                                    Please choose a password to encrypt and decrypt the data for this school term.
                                </span>
                            </div>
                            <div class="form-group">
                                <label>Confirm School Term Encryption Password</label>
                                <input
                                    v-model="confirmPassword"
                                    type="password"
                                    class="form-control"
                                    :name="randomizeFormName"
                                    :class="{'is-invalid': !passwordMatch}"
                                    autocomplete="password"
                                >
                                <span class="form-text text-muted">
                                    Please confirm the password.
                                </span>
                            </div>
                        </div>
                        <div class="kt-portlet__foot mb-5">
                            <div class="kt-form__actions">
                                <button
                                    type="button"
                                    class="btn btn-info pull-right"
                                    :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                                    @click="setSchoolTermEncryptionKey"
                                >
                                    Set Encryption Password
                                </button>
                                <button
                                    v-if="isSetupWizard && step.status == 'danger'"
                                    type="button"
                                    class="btn btn-secondary pull-right mr-2"
                                    @click="skipSetupWizard"
                                >
                                    Skip this Step
                                </button>
                            </div>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <!-- unlock client side -->
        <template v-else>
            <div class="col col-lg-6 offset-lg-3 pt-5">
                <div class="kt-portlet kt-bg-warning kt-portlet--skin-solid kt-portlet--height-fluid">
                    <div class="kt-portlet__body">
                        <div class="kt-widget7 kt-widget7--skin-light">
                            <div class="kt-widget7__desc my-3">
                                Remove encryption for this school term by clicking the button below.
                                This will remove the encryption key and all data will be decrypted for this term for all staff.
                            </div>
                            <div class="kt-widget7__content">
                                <div class="kt-widget7__userpic">
                                    <img src="/images/lock.svg" style="width: 260px; min-width: 200px;">
                                </div>
                            </div>
                            <div class="kt-widget7__button">
                                <button
                                    type="button"
                                    style="min-width: 260px; margin: auto;"
                                    class="btn btn-danger btn-lg kt-font-lg btn-upper mt-3"
                                    @click="removeEncryption"
                                >
                                    Remove School Term Encryption
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import * as network from '../network';
import Types from '../store/Types';

export default Vue.extend({
    name: 'DataEncryption',
    components: {
    },
    data() {
        return {
            isNewPassword: true,
            saving: false,
            password: '',
            newPassword: '',
            confirmPassword: '',
        };
    },
    computed: {
        isAdminLocked() {
            const school = this.$store.state.database.schools
                .find((s) => s.schoolId == this.$store.state.user.school.schoolId);
            const { schoolYear } = this.$store.state.user.school;
            const { isSuperAdmin } = this.$store.state.user;
            const { subscriptions } = school;
            const subscription = subscriptions.find((s) => s.schoolYear == schoolYear );
            let isAdminLocked = false;
            if (subscription) {
                isAdminLocked = subscription.adminLocked && !isSuperAdmin;
            }
            return isAdminLocked;
        },
        user() {
            return this.$store.state.user;
        },
        finalized() {
            return this.$store.state.user.school.finalized;
        },
        enableEncryption: {
            get() {
                const { finalized } = this.$store.state.user.school;
                const { encryptionEnabled } = this.$store.state.user.subscription;
                if (finalized || encryptionEnabled) {
                    return encryptionEnabled;
                } else {
                    const { bypassWizardEncryption } = this.$store.state.wizard;
                    if (bypassWizardEncryption == 'true') return true;
                    return false;
                }

            },
            set(enabled) {
                const { finalized } = this.$store.state.user.school;
                if (finalized) return;
                const value = enabled ? 'true' : '';

                this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, {
                    routeName: this.step.routeName,
                    value,
                });

                this.$store.dispatch(Types.actions.CALCULATE_SETUP_STEPS);
            },
        },
        hasEncryptionCredentials() {
            return Boolean(this.$store.state.database.schoolTermEncryptionKey);
        },
        clientSideEncryptionEnabled() {
            return this.user.school.clientSideEncryptionEnabled;
        },
        serverSideEncryptionEnabled() {
            return this.user.school.serverSideEncryptionEnabled;
        },
        isSetupWizard() {
            return this.$route.name == 'SetupWizardRoute';
        },
        step() {
            return this.$store.state.wizard.steps
                .find((step) => step.routeName == 'setup-encryption');
        },
        encryptionHash() {
            return this.user.school.encryptionHash;
        },
        randomizeFormName() {
            return `randomized-${Math.random().toString(36).substring(7)}`;
        },
        isValidSchoolPassword() {
            const password = this.password || '';
            const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
            if (password.length <= 8) return false;
            if (!/\d/.test(password)) return false;
            if (!/[A-Z]/.test(password)) return false;
            if (!format.test(password)) return false;
            return true;
        },
        passwordMatch() {
            return this.password && this.password === this.confirmPassword;
        },
        formIsValid() {
            return this.isValidSchoolPassword && this.passwordMatch;
        },
        hasEncryptionHash() {
            return Boolean(this.user.school.encryptionHash);
        },
        canEnableEncryption() {
            return this.user.twoFactorAuth
                && this.user.twoFactorAuth.twoFactorAuthEnabled
                && this.$store.state.user.hasLocalPassword;
        },

    },
    mounted() {
        const { isSetupWizard, isAdminLocked, enableEncryption } = this;
        if (isSetupWizard && isAdminLocked && !enableEncryption) {
            return this.$router.push({ name: 'SetupWizard' });
        }

        if (this.$store.state.user.school.externalSchoolId == '00X000') {
            this.password = 'Welcome1!';
            this.confirmPassword = 'Welcome1!';
        }
    },
    methods: {
        skipSetupWizard() {
            const { step } = this;
            this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, { routeName: 'setup-encryption', value: 'true' });
        },
        startDecryption() {
            this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, true);
        },
        setSchoolTermEncryptionKey() {
            const { schoolTermId, schoolId } = this.$store.state.user.school;
            const { password, showError, formIsValid } = this;
            const { commit, dispatch } = this.$store;
            if (!formIsValid) return showError('Please enter a valid password and confirm it.');
            // if (!window.confirm('Are you sure you want to enable encryption for all users this school term?')) return;

            const params = {
                url: { schoolTermId, schoolId },
                body: { password },
            };
            network.cipher.setSchoolTermEncryptionPassword(params, (err, res) => {
                if (err) return showError(err);
                if (res && res.exists) return showError('A password already exists for this school term.');

                const { encryptionHash } = res;

                commit(Types.mutations.SET_ENCRYPTION_HASH, encryptionHash);
                dispatch(Types.actions.ENABLE_SERVER_SIDE_ENCRYPTION, {
                    schoolPassword: password,
                    callback: (err2) => {
                        if (err2) return showError(err2);
                        commit(Types.mutations.SET_SERVER_SIDE_ENCRYPTION_ENABLED, true);
                        window.location.reload();
                    },
                });
            });
        },
        removeEncryption() {
            const { showError } = this;
            if (!this.$store.state.database.schoolTermEncryptionKey) {
                return showError('Please unlock before continuing.');
            }
            if (!window.confirm('Are you sure you want to remove encryption for all users this school term?')) return;

            const { commit, dispatch } = this.$store;
            dispatch(Types.actions.DISABLE_SERVER_SIDE_ENCRYPTION, {
                callback: (err) => {
                    if (err) return showError(err);
                    commit(Types.mutations.SET_ENCRYPTION_HASH, null);
                    commit(Types.mutations.SET_SERVER_SIDE_ENCRYPTION_ENABLED, false);
                    window.location.reload();
                },
            });
        },
    },
});
</script>
